import dayjs from "dayjs";

const resumeDataAPI = "https://script.google.com/macros/s/AKfycbxaNtZF6lXIGoD3hNNwCTkN5fytGml2RTFC0KtVpoE1jUlBaoBlKf-foKRpF3hSLKk9/exec"
const pageViewHistoryAPI = "https://script.google.com/macros/s/AKfycbyVLvVljp4JIWMBlZqWhCIXm0nFCJq3GfXS6Q-05guYRkpR6eR1YlNfDL2cE4uvFaFQBA/exec"


export async function registerStreak(email : string){



    try{
       const response =  await getStreak(email);
       const streak_data = response.data.view_history[0];
       if(streak_data){

            const page_view_history = Array.from(JSON.parse(streak_data?.page_view_history));

            console.log(page_view_history.length , "page_view_hist");
            if(page_view_history.length){

                const today = dayjs(new Date().toDateString());
                // @ts-ignore 
                const last_page_visit = dayjs(new Date(page_view_history[page_view_history.length - 1]))
                const difference = today.diff(last_page_visit , "day");

                console.log(difference , "difference in time");

                if(difference == 1){
                    return increamentStreak(streak_data)
                }else if (difference == 0){
                    return 0
                }else{
                    return resetStreak(streak_data)
                }

                }else{
                    return initializeStreak(email)
                }
       }else{
         initializeStreak(email)
       }

    //    console.log(streak_data_arr[0] , "streak_data_arr");
    }catch(err){
        console.log(err);
    }
}

async function initializeStreak(email : string){
    let res : any;

    try{

        const page_view_history = new Set();

        page_view_history.add(new Date().toDateString());
        
        if(email){
            console.log(JSON.stringify(page_view_history) , "page view history")
        
            res =  await fetch(`${pageViewHistoryAPI}?action=doPost`, {
                method : "POST",
                headers : {
                    "Content-Type": "text/plain;charset=utf-8"
                },
                redirect : "follow",
                body : JSON.stringify({
                    user_id : email,
                    streak_count : 1,
                    page_view_history : JSON.stringify(Array.from(page_view_history)),
                    name_of_operation : "create"
                })
            })

            const response = await res.json();
            return response;

        }
    
    }catch(err){
        console.error(err);
    }

}

async function resetStreak(streak_data : {user_id : string , streak_count : number , page_view_history : [string]} ){

    let res : any;

    try{

        const page_view_history = new Set();
        const streak_count = 1;
        
        for(let element of JSON.parse(streak_data.page_view_history as any )){
            page_view_history.add(element)
        }

        page_view_history.add(new Date().toDateString())

        if(streak_data.user_id){
            res =  await fetch(`${pageViewHistoryAPI}?action=doPost`, {
                method : "POST",
                headers : {
                    "Content-Type": "text/plain;charset=utf-8"
                },
                redirect : "follow",
                body : JSON.stringify({
                    user_id : streak_data.user_id,
                    streak_count : streak_count,
                    page_view_history : JSON.stringify(Array.from(page_view_history)),
                    name_of_operation : "update"
                })
            })

            const response = await res.json();
            return response;

        }
    
    }catch(err){
        console.error(err);
    }

}


async function increamentStreak(streak_data : {user_id : string , streak_count : number , page_view_history : [string]} ){

    let res : any;

    try{

        const page_view_history = new Set();
        const streak_count = streak_data.streak_count + 1;
        
        for(let element of JSON.parse(streak_data.page_view_history as any )){
            page_view_history.add(element)
        }

        page_view_history.add(new Date().toDateString())

        if(streak_data.user_id){
            res =  await fetch(`${pageViewHistoryAPI}?action=doPost`, {
                method : "POST",
                headers : {
                    "Content-Type": "text/plain;charset=utf-8"
                },
                redirect : "follow",
                body : JSON.stringify({
                    user_id : streak_data.user_id,
                    streak_count : streak_count,
                    page_view_history : JSON.stringify(Array.from(page_view_history)),
                    name_of_operation : "update"
                })
            })

            const response = await res.json();
            return response;

        }
    
    }catch(err){
        console.error(err);
    }

}

export async function getStreak( email : string){
    let res : any;

    try{
        if(email){
            console.log(email);
            res =  await fetch(`${pageViewHistoryAPI}?action=doGet&user_id=${email}`, {
                method : "GET"
            })

            const response = await res.json();
            console.log(response);
            return response;

        }
    
    }catch(err){
        console.error(err);
    }
    
}


async function getData (sheet_name : string, id? : number | null| string)  {
    

    const api = "https://script.google.com/macros/s/AKfycbwAoOgrsf4PlCy4Hi7sg6D3gUxfzmCeuTl8Bj3suht4cGIFNGLKpLniaJ3jHHMP2r2g/exec"
    let res : any ;
    
    try{
        if(id){
            res =  await fetch(`${api}?action=doGet&sheet_name=${sheet_name}&id=${id}`, {
                method : "GET"
            })

            const response = await res.json();
            console.log(response , "response");
            return response;

        }else{
            res =  await fetch(`${api}?action=doGet&sheet_name=${sheet_name}`, {
                method : "GET"
            })

            const response = await res.json();
            console.log(response , "response");
            return response;
        }
    
    }catch(err){
        console.error(err);
    }

   
}



export async function createNewResume(
    data : any
){
    

    let res : any;

    try{
        console.log(data.id)
        if(data.id){
            res =  await fetch(`${resumeDataAPI}?action=doPost`, {
                method : "POST",
                headers : {
                    "Content-Type": "text/plain;charset=utf-8"
                },
                redirect : "follow",
                body : JSON.stringify(data)
            })

            const response = await res.json();
            return response;

        }
    
    }catch(err){
        console.error(err);
    }
    
}

export async function updateResume(
    data : any
){
    
    let res : any;

    try{
        console.log(data.id)
        if(data.id){
            res =  await fetch(`${resumeDataAPI}?action=doPost`, {
                method : "POST",
                headers : {
                    "Content-Type": "text/plain;charset=utf-8"
                },
                redirect : "follow",
                body : JSON.stringify(data)
            })

            const response = await res.json();
            return response;

        }
    
    }catch(err){
        console.error(err);
    }
    
}

export async function deleteResumeData(
    data : any
){
    

    let res : any;
    console.log(data , "data resume");
    try{
        console.log(data.id)
        if(data.id){
            res =  await fetch(`${resumeDataAPI}?action=doPost`, {
                method : "POST",
                headers : {
                    "Content-Type": "text/plain;charset=utf-8"
                },
                redirect : "follow",
                body : JSON.stringify({...data , name_of_operation : "delete"})
            })

            const response = await res.json();
            return response;

        }
    
    }catch(err){
        console.error(err);
    }
    
}






export async function getResumeData(
    email : string 
){

    let res : any;

    try{
        if(email){
            console.log(email);
            res =  await fetch(`${resumeDataAPI}?action=doGet&user_id=${email}`, {
                method : "GET"
            })

            const response = await res.json();
            console.log(response);
            return response;

        }
    
    }catch(err){
        console.error(err);
    }
    
}


export async function registerClickData(
    sheet_name : string,
    email : string,
    name : string,
    id : number,
    whatsappNumber? : string
){

    const api = "https://script.google.com/macros/s/AKfycbydsYrtWeQ07zPXzl1bR4xLVeogue3GJk8DOsU_VvwUHt8i1590pbVZ2jnIlNnu08PO/exec";
    let res : any;

    try{
        console.log(id)
        if(id){
            res =  await fetch(`${api}?action=doPost`, {
                method : "POST",
                headers : {
                    "Content-Type": "text/plain;charset=utf-8"
                },
                redirect : "follow",
                body : JSON.stringify({
                    sheet_name,
                    email,
                    name,
                    id,
                    whatsappNumber
                })
            })

            const response = await res.json();
            return response;

        }
    
    }catch(err){
        console.error(err);
    }
}


export async function getClickData(userEmail : string | undefined ){

    const api = "https://script.google.com/macros/s/AKfycbydsYrtWeQ07zPXzl1bR4xLVeogue3GJk8DOsU_VvwUHt8i1590pbVZ2jnIlNnu08PO/exec"
    let res : any ;
    
    try{
        if(userEmail){
            console.log(userEmail);
            res =  await fetch(`${api}?action=doGet&email=${userEmail}`, {
                method : "GET"
            })

            const response = await res.json();
            console.log(response);
            return response;

        }
    
    }catch(err){
        console.error(err);
    }
}   

export async function getArticleData(){

    const api = "https://script.google.com/macros/s/AKfycbzezPOtMWaAgTIe9KDpllc-Vqw7euPG_3K2WexDaYAnJvyYVNxe_a0G_mNX1lYC3WZDoA/exec"
    let res : any ;
    
    try{
            res =  await fetch(`${api}?action=doGet`, {
                method : "GET"
            })

            const response = await res.json();
            console.log(response , "response");
            return response;
    
    }catch(err){
        console.error(err);
    }
}   


export default getData;